<template>
  <div class="h-450">
    <b-card class="mt-7">
      <b-row class="match-height">
        <b-col md="12" class="mb-2 flex">
          <!-- TODO: move component filters -->
          <b-row>

            <b-col lg="12" class="d-flex justify-content-between flex-wrap">
              <b-button-group class="body-button-center mr-0 mb-1">
                <b-button 
                  :class="change_view_card ? 'button-outline-header-grid-no-active' : 'button-outline-header-grid-active'" 
                  :variant="change_view_card ? 'button-outline-header-grid-no-active' : 'button-outline-header-grid-active'" 
                  @click="change_view_card = false"
                >
                  <b-icon icon="grid-fill" font-scale="1.5" variant="dark"></b-icon>
                </b-button>
                <b-button 
                  :class="change_view_card ? 'button-outline-header-grid-active ml-05' : 'button-outline-header-grid-no-active ml-05'" 
                  :variant="change_view_card ? 'button-outline-header-grid-active' : 'button-outline-header-grid-no-active'" 
                  @click="change_view_card = true"
                >
                  <b-icon icon="list-ol" font-scale="1.5" variant="dark"></b-icon>
                </b-button>
              </b-button-group>
              <div class="d-flex flex-wrap flex-lg-nowrap">
                <b-dropdown
                  id="dropdown-right"
                  :text="$t('campaigns.actions')"
                  variant="flat-secondary"
                  :disabled="disabledUpdateCampaign()"
                  class="avenir-medium mb-1 dropdown-actions-inner-influencers"
                  right
                >
                  <b-dropdown-item @click="selectAll()" :disabled="disabledUpdateCampaign()">
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="16"
                      class="align-middle mr-05"
                    />
                    {{ $t('campaigns.actionSelectAll') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="unselectAll()" :disabled="disabledUpdateCampaign()">
                    <feather-icon
                      icon="XSquareIcon"
                      size="16"
                      class="align-middle mr-05"
                    />
                    {{ $t('campaigns.actionRemoveSelection') }}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="exportProposals()">
                    <feather-icon icon="FileTextIcon" size="16" class="align-middle mr-05"/>
                    {{$t('campaigns.export_proporsals')}}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="approvedUsers()"
                    :disabled="disabledUpdateCampaign() || checkedNames.length === 0"
                  >
                    <feather-icon
                      icon="UserCheckIcon"
                      size="16"
                      class="align-middle mr-05"
                    />
                    {{ $t('campaigns.approveBtn') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="rejectedUsers()"
                    :disabled="disabledUpdateCampaign() || checkedNames.length === 0"
                  >
                    <feather-icon
                      icon="UserXIcon"
                      size="16"
                      class="align-middle mr-05"
                    />
                    {{ $t('campaigns.rejectBtn') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="removedUsers()"
                    :disabled="disabledUpdateCampaign() || checkedNames.length === 0"
                  >
                    <feather-icon
                      icon="UserMinusIcon"
                      size="16"
                      class="align-middle mr-05"
                    />
                    {{ $t('campaigns.deleteBtn') }}
                  </b-dropdown-item>
                </b-dropdown>
                
                <component :is="vue_select" :options="$t('campaigns.filters.status')" label="text" v-model="filters.status" class="select-filters-type-inner-campaigns aditional-width mb-1" :append-to-body="true">
                  <template #option="{ text, value }">
                    <div class="avenir-medium">
                      {{  text }} ({{ header_data[value] }})
                    </div>
                  </template>
                  <template #selected-option="{ text, value }">
                    {{  text }} ({{ header_data[value] }})
                  </template>
                </component>
                <component :is="vue_select" :options="$t('campaigns.filters.type')" label="text" v-model="filters.type" class="select-filters-type-inner-campaigns mb-1" :append-to-body="true">
                </component>

                <b-dropdown class="dropdown-networks-filter-inner form-control" variant="link" toggle-class="" no-caret @show="caret_dropdown_nets = true" @hidden="caret_dropdown_nets = false">
                  <template #button-content>
                    {{ $t('network') }} ({{ filters.networks.length === 0 ? $t('campaigns.all') : filters.networks.length }})

                    <feather-icon :icon="caret_dropdown_nets ? 'ChevronUpIcon' : 'ChevronDownIcon'" class="float-right"></feather-icon>
                  </template>
                  <b-dropdown-header class="p-0">
                    <ul class="ul-networks-select">
                      <li v-for="i in networks_avaibles" :key="i.value" :class="{'active-li': filters.networks.find(n => n.value === i.value)}" @click="toggleActionNet(i)">
                        {{ i.text }}
                        <feather-icon icon="CheckIcon" class="float-right" v-if="filters.networks.find(n => n.value === i.value)"></feather-icon>
                      </li>
                    </ul>
                  </b-dropdown-header>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <div class="w-100" :key="change_influencers" v-if="!change_view_card">
          <b-row class="w-100 m-0" v-if="influencers.length > 0" :key="change_influencers">
            <b-col v-for="(influencer, index) in influencers" :key="index" class=" col-12 col-sm-6 col-lg-4 col-xl-3">
              <!-- TODO: Remove prop style -->
              <b-card
                class="text-center card-all-users-profiles"
                style="box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1) !important"
                footer-class="without-border"
                :ref="`card-influencer-${influencer.uuid}`"
              >
                <div class="mb-1 position-relative">
                  <b-form-checkbox
                    v-model="checkedNames"
                    :value="influencer.uuid"
                    class="custom-control-primary"
                    :disabled="disabledUpdateCampaign()"
                  >
                  </b-form-checkbox>
                  <feather-icon
                    @click="deletedCreator(influencer.uuid, influencer.influencer.username)"
                    icon="XIcon"
                    size="16"
                    class="align-middle text-secondary position-absolute cursor-pointer delete-creator"
                    :disabled="disabledUpdateCampaign()"
                  />
                </div>
                <div class="profile-image-wrapper mb-2">
                  <div class="profile-image p-0 position-relative">
                      <b-avatar class="socialnet-campaigns p-0" variant="light">
                        <b-img  :src="`${getIconWhite(influencer.influencer.network.toLowerCase())}`" :class="getIconColor(influencer.influencer.network.toLowerCase())" fluid></b-img>
                      </b-avatar>
                    <b-avatar
                      size="200"
                      variant="light-secondary"
                      :src="influencer.influencer.image_url"
                    >
                      <img class="img-200" :src="getImageInfluencer(influencer.influencer)" @error="replaceInsta($event, influencer.influencer.username, influencer.influencer.network)">
                    </b-avatar>
                  </div>
                </div>
                <router-link :to="'/perfil/' + influencer.influencer.network.toLowerCase() + '/' + influencer.influencer.username">
                  <h3>
                    {{ getUsername(influencer.influencer) }}
                    <image-verified v-if="influencer.influencer.is_verified" class="square-20"/>
                  </h3>
                </router-link>
                <h5 class="text-muted font-weight-bolder">
                  {{ $t(`influencerCategory.${influencer.influencer.calculate_category}`)}}
                </h5>

                <div :class="getClasses(influencer.influencer)">
                  <div v-if="influencer.influencer.followers > 0" class="mb-1 col-12 col-sm-6">
                    <h6 class="text-muted font-weight-bolder">
                      {{ $t('campaigns.followers') }}
                    </h6>
                    <h3 class="mb-0">
                      {{ engagement(influencer.influencer.followers) }}
                    </h3>
                  </div>
                  <div v-if="influencer.influencer.engagement_rate > 0" class="mb-1 col-12 col-sm-6">
                    <h6 class="text-muted font-weight-bolder">
                      {{ $t('campaigns.erTitle') }}
                    </h6>
                    <h3 class="mb-0">
                      {{ decimalFixed(influencer.influencer.engagement_rate, 2) }} %
                    </h3>
                  </div>
                </div>
                <template #footer>
                  <hr class="mb-2">
                  <StatusInfluencer @update_influencer="updateInfluencer" :influencerStatus="influencer.status" :influencerUuid="influencer.uuid"></StatusInfluencer>
                </template>
              </b-card>
            </b-col>

            <div class="col-12 row m-0 p-0" v-if="loading_creators && (this.influencers.length <  this.total_results)">
              <b-col class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="i in 4" :key="i">
                <b-card class="card-skeleton">
                  <b-skeleton class="skeleton profile-avatar mb-2"></b-skeleton>
                  <b-skeleton class="skeleton h1 col-10"></b-skeleton>
                  <b-skeleton class="skeleton col-8 mb-5"></b-skeleton>
  
                  <b-skeleton class="skeleton col-4"></b-skeleton>
                  <b-skeleton class="skeleton col-4 mb-7"></b-skeleton>
  
                  <div class="d-flex w-100 mb-1">
                    <b-skeleton type="button" class="skeleton w-100 mr-2"></b-skeleton>
                    <b-skeleton type="button" class="skeleton w-100 ml-2"></b-skeleton>
  
                  </div>
                </b-card> 
              </b-col>
            </div>
          </b-row>
          <div v-else class="d-flex mb-2 align-items-center justify-content-center flex-column">
            <img v-if="status_influencers === 'all'" src="@/assets/images/elements/campaign/all.png" class="w-50"/>
            <img v-if="status_influencers === 'pending'" src="@/assets/images/elements/campaign/pending.png" class="w-50"/>
            <img v-if="status_influencers === 'approved'" src="@/assets/images/elements/campaign/approved.png" class="w-50"/>
            <img v-if="status_influencers === 'rejected'" src="@/assets/images/elements/campaign/rejected.png" class="w-50"/>
            <no-Results
              :title= "$t('campaigns.influecersNotFound')"
              :show_button= "false"
              :no_border="true"
              v-else-if="status_influencers !== 'all' && status_influencers !== 'pending' && status_influencers !== 'approved' && status_influencers !== 'rejected'"
            />
            <b-button variant="outline-primary" pill @click="$emit('openSidebar')" :disabled="disabledUpdateCampaign()">{{ $t('campaigns.addInfluencer') }}</b-button>
          </div>
        </div>
        <div class="w-100 px-1" v-else>
          <div class="position-relative overflow-scroll">
            <vue-good-table
              :columns="$t('campaigns.tables.proposals_influencers')"
              :rows="influencers"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }"
              :selected-rows.sync="checkedNames"
              :pagination-options="{
                enabled: true,
                perPage: per_page,
              }"
              ref="my-table"
              @on-selected-rows-change="selectionChanged"
              :class="`${influencers.length > 0 ? 'vgt-table-influencers-inner-campaign' : ''} mt-1`"
              :key="update_table"
            >
              <div slot="emptystate">
                <no-Results
                  :title= "$t('campaigns.influecersNotFound')"
                  :show_button= "true"
                  :no_border="true"
                  :class_btn="'blue-button'"
                  :text_button="$t('campaigns.addInfluencer')"
                  :btn_action="openSidebarAddInfluencer"
                />
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'user'" class="text-nowrap row-selected-table">
                  <div class="d-flex p-1">
                    <b-avatar class="" variant="light" :src="getImageInfluencer(props.row.influencer)" @error="replaceInsta($event, props.row.influencer.username, props.row.influencer.network)" size="40">
                      <template #badge>
                        <b-avatar size="18px" class="avatar-absolute-net-influecer-list" variant="ligth">
                          <b-img :src="getIconWhite(props.row.influencer.network)" :class="getIconColor(props.row.influencer.network, false)" fluid/>
                        </b-avatar>
                      </template>
      
                    </b-avatar>
                    <div class="ml-1">
                      <strong>{{ props.row.influencer.name }}</strong><image-verified v-if="props.row.influencer.is_verified" class="ml-05"/>
                      <p class="mb-0 gray-color-text">@{{props.row.influencer.username}}</p>
                    </div>
                  </div>
                </span>
  
                <span v-if="props.column.field === 'type'" class="mr-1">
                  <b-badge variant="light-secondary">
                    {{ $t(`influencerCategory.${props.row.influencer.calculate_category}`) }}
                  </b-badge>
                </span>

                <span
                  v-if="props.column.field === 'network'"
                  class="text-center d-flex mr-1"
                >
                  <b-avatar variant="ligth" size="20" class="mr-05">
                    <b-img class="icon-squad-network-avatar" :src="getIconWhite(props.row.influencer.network)" :class="getIconColor(props.row.influencer.network, false)" fluid/>
                  </b-avatar>
                  {{nameNetworks(props.row.influencer.network)}}
                </span>
  
                <span v-if="props.column.field === 'followers'">
                  <div v-if="props.row.influencer.followers > 0">{{ getFormat(props.row.influencer.followers) }}</div>
                </span>
      
                <span v-if="props.column.field === 'er'">
                  <div v-if="props.row.influencer.engagement_rate > 0">{{ props.row.influencer.engagement_rate.toFixed(2) }}%</div>
                  <span v-else>--</span>
                </span>
      
                <span v-if="props.column.field === 'actions'" class="d-flex align-items-center">
                  <div class="d-flex" v-if="props.row.status === 'pending'">
                    <b-button variant="flat-secondary" :id="`check-${props.index}`" @click="actionTableStatus('approved', props.row.uuid)">
                      <feather-icon icon="CheckIcon" class="text-success"></feather-icon>
                    </b-button>
                    <b-tooltip :target="`check-${props.index}`">{{ $t('creator.approve') }}</b-tooltip>
  
                    <b-button variant="flat-secondary" :id="`reject-${props.index}`"  @click="actionTableStatus('rejected', props.row.uuid)">
                      <feather-icon icon="XIcon" class="text-danger"></feather-icon>
                    </b-button>
                    <b-tooltip :target="`reject-${props.index}`">{{ $t('creator.decline') }}</b-tooltip>

                  </div>
                  <div v-else>
                    <b-badge :variant="props.row.status === 'approved' ? 'light-success' : props.row.status === 'rejected' ? 'light-danger' : 'light-secondary'">
                      {{ $t(`campaigns.${props.row.status}`) }}
                    </b-badge>
                  </div>
                  <b-button variant="flat-secondary" @click="deletedCreator(props.row.uuid, props.row.influencer.username)" :class="{'ml-1': props.row.status !== 'pending'}" :id="`delete-${props.index}`">
                    <feather-icon icon="TrashIcon"></feather-icon>
                  </b-button>
                  <b-tooltip :target="`delete-${props.index}`">{{ $t('campaigns.deleteBtn') }}</b-tooltip>

                </span>
              </template>
              
              <!-- pagination -->
              <template slot="pagination-bottom">
                <div class="d-flex justify-content-end flex-wrap">
  
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total_results"
                      :per-page="per_page"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      v-model="current_page"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <StatusInfluencer @update_influencer="updateInfluencer" class="d-none" ref="status-influencer-component"></StatusInfluencer>

            <div class="container-loader-vue-good-table" v-if="loading_creators">
              <b-spinner class="spinner-loader"></b-spinner>
            </div>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BFormCheckbox, 
  BButtonGroup,
  BSpinner,
  BDropdown, 
  BDropdownItem, BDropdownDivider, BButton, BImg, BSkeleton,
  BDropdownHeader,
  BPagination,
  BBadge,
  BTooltip,
} from 'bootstrap-vue'
import service from "@/services/campaign";
import config_env from '@/services/config';
import imgProfile from "@/assets/images/elements/error.png";
import { getIconWhite, getIconColor } from '@/libs/utils/icons';
import { getImageInfluencer, getMotivationalPhrase } from '@/libs/utils/others';
import { decimalFixed, getFormat, nameNetworks } from '@/libs/utils/formats';
import { isUrl, getAssetUrl, checkResourceExists } from '@/libs/utils/urls'
import { hasPermission } from '@/libs/utils/user';
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    StatusInfluencer: () => import('@/views/campaings/statusInfluencer'),
    BTooltip,
    BRow,
    BPagination,
    BCol,
    BButtonGroup,
    BCard,
    BDropdownHeader,
    BAvatar,
    BSpinner,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BImg,
    BSkeleton,
    BBadge,
    VueGoodTable,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),

  },
  props: {
    campaign_name: {
      type: String,
      default: ''
    },
    header_data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      getMotivationalPhrase,
      hasPermission,
      checkResourceExists,
      getAssetUrl,
      isUrl,
      decimalFixed,
      getImageInfluencer,
      getIconWhite,
      getIconColor,
      getFormat,
      nameNetworks,
      influencers_without_filter: [],
      influencers: [],
      checkedNames: [],
      change_filter: false,
      has_next_influencers: false,
      current_page: 1,
      loading_creators: false,
      change_influencers: 0,
      filter_category: '',
      interval_loading: null,
      total_results: 0,
      change_view_card: true,
      vue_select: null,
      networks_avaibles: [
        { value: 'instagram', text: 'Instagram' },
        { value: 'youtube', text: 'YouTube' },
        { value: 'tiktok', text: 'TikTok' },
        { value: 'twitter', text: 'Twitter' },
        { value: 'twitch', text: 'Twitch' }
      ],
      filters: {
        type: this.$t('campaigns.filters.type')[0],
        status: this.$t('campaigns.filters.status')[0],
        networks: [],
      },
      status_influencers: 'all',
      caret_dropdown_nets: false,
      update_table: false,
    }
  },
  created() {
    this.filters.networks = this.networks_avaibles;
    this.loading_creators = true
    this.getInfluencers()
  },
  computed: {
    per_page() {
      return 20;
    }
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    toggleActionNet(net) {
      this.filters.networks = this.filters.networks.some(i => i.value === net.value) ? this.filters.networks.filter(i => i.value !== net.value) : [...this.filters.networks, net];
    },
    selectionChanged(data) {
      this.checkedNames = data.selectedRows.map(i => i.uuid);
    },
    openSidebarAddInfluencer() {
      this.$emit('openSidebar');
    },
    
    getInfluencers() {
      this.loading_creators = true;
      service.getCampaignInfluencers(this.$route.params.uuid, this.current_page, this.per_page, this.status_influencers, this.filter_category)
        .then(response => {
          this.total_results = response.count;
          this.loading_creators = false;
          if (!this.change_view_card) {
            this.influencers_without_filter = response.results;
            this.influencers = response.results;
          } else {
            this.influencers_without_filter = response.results;
            this.influencers = response.results;
          }
          
          this.has_next_influencers = response.next !== null;
        });
    },
    getScrollInfiniteInfluencers() {
      if ((this.influencers.length <  this.total_results) || this.influencers.length === 0) {
        this.loading_creators = true;
        service.getCampaignInfluencers(this.$route.params.uuid, this.current_page, this.per_page, this.status_influencers, this.filter_category)
          .then(response => {
            this.total_results = response.count;
            this.loading_creators = false;
            this.influencers_without_filter = this.influencers_without_filter.concat(response.results);
            this.influencers = this.influencers.concat(response.results);
            this.has_next_influencers = response.next !== null;
          });
      }
    },
    getUsername(influencer) {
      if (influencer.network === 'youtube') return influencer.name
      return influencer.username
    },
    replaceInsta(event, username, network) {
      if (network.toLowerCase() === 'instagram') {
        this.$http.get(`${config_env.urlCore}/api/v1/finder/profile/image/${username}`)
          .then(function(response) {
            const img_url = isUrl(response.data.response.image) ? response.data.response.image : getAssetUrl(response.data.response.image);
            checkResourceExists(img_url).then(function(result) {
              if (!result) {
                event.target.src = imgProfile;
              } else {
                event.target.src = img_url;
              }
            });

          })
          .catch(() => {
            event.target.src = imgProfile;
          });
      } else event.target.src = imgProfile;
    },
    updateInfluencer(influencer_updated) {
      this.$emit('update_header_data', influencer_updated.campaign_header);
      this.influencers = this.influencers.map(i => {
        return i.uuid === influencer_updated.uuid ? influencer_updated : i;
      });
    },
    engagement (num) {
      let result = ''
      if (num < 1000) {
        result = num
      }
      if (num > 999) {
        result = `${(Math.round(num / 100) / 10)} K`
      }
      if (num > 999999) {
        result = `${(Math.round(num / 100000) / 10)} M`
      }
      if (num > 999999999) {
        result = `${(Math.round(num / 100000000) / 10)} B`
      }
      return result
    },
    categoryFilterAll () {
      this.influencers_without_filter = []
      this.filter_category = ''
      this.current_page = 1
      this.loading_creators = true
      this.getInfluencers()
      this.influencers = this.influencers_without_filter
      this.change_filter = !this.change_filter
    },
    categoryFilter (category) {
      this.loading_creators = true
      this.influencers_without_filter = []
      this.current_page = 1
      this.filter_category = `&category=${category}`
      this.getInfluencers()
      this.change_filter = !this.change_filter
      this.influencers = []
      this.influencers_without_filter.forEach(influencer => {
        if (influencer.influencer.calculate_category === category) {
          this.influencers.push(influencer)
        }
      })
    },
    actionTableStatus(action, influencer_uuid) {
      const status_influencer = this.$refs['status-influencer-component'];
      if (action === 'approved' && status_influencer) {
        status_influencer.approvedButton(influencer_uuid);
      } else if (status_influencer) {
        status_influencer.rejectedButton(influencer_uuid);
      }
    },
    selectAll () {
      if (this.disabledUpdateCampaign()) return;
      if (this.change_view_card && (this.checkedNames.length === 0 || this.checkedNames.length < this.influencers.length)) {
        this.$nextTick(() => {
          const selectAllCheckbox = document.querySelector(
            "table thead tr th:first-child input[type='checkbox']"
          );
          if (selectAllCheckbox) {
            selectAllCheckbox.click();
          }
        });
      } else {

        this.checkedNames = []
        this.influencers.forEach(i => {
          this.checkedNames.push(i.uuid)
        })
      }
    },
    unselectAll() {
      if (this.change_view_card && this.checkedNames.length > 0) {
        this.$nextTick(() => {
          const selectAllCheckbox = document.querySelector(
            "table thead tr th:first-child input[type='checkbox']"
          );
          if (selectAllCheckbox) {
            selectAllCheckbox.click();

            if (this.checkedNames.length !== this.influencers.length) selectAllCheckbox.click();
          }
        });
      } else {
        this.checkedNames = []
      
      }
    },
    statusApproved () {
      this.$swal({
        title: this.$t('campaigns.approveInfluencer'),
        text: this.$t('campaigns.influencerSuccess'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    approvedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.approveCreator'),
        text: this.$t('campaigns.approveSuccess'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.realty.accept'),
        cancelButtonText: this.$t('campaigns.realty.cancel'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false

      }).then(result => {
        if (result.value) {
          this.statusUsers('approved')
        }
      })
    },
    rejectedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.textConfirmRejectCreators'),
        text: this.$t('campaigns.textRejectCreators'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmRejectCreators'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          this.statusUsers('rejected')
        }
      })
    },
    removedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.textConfirmCreators'),
        text: this.$t('campaigns.textCreators'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmCreators'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          this.statusUsers('removed')
        }
      })
    },
    statusUsers (status) {
      service.influencerAction(this.$route.params.uuid, this.checkedNames, status)
        .then((response) => {
          this.change_influencers += 1
          
          this.checkedNames.forEach(influencer_uuid => {
            const influencer = this.influencers.find(influ => influ.uuid === influencer_uuid)
            if (influencer) {
              if (status !== 'removed') {
                influencer.status = status
              } 
            }
          })
          if (status === 'approved') {
            this.$emit('update_header_data', response.results[0].campaign_header)
            this.approvedInflu()
          }
          if (status === 'rejected') {
            this.$emit('update_header_data', response.results[0].campaign_header)
            this.rejectedInflu()
          }
          if (status === 'removed') {
            this.removedInflu()
            this.influencers = this.influencers.filter(i => !this.checkedNames.includes(i.uuid));
            this.$emit('update_counter_header')
          } else {
            this.$emit('childStatus', status)
          }
        })
        .catch(() => {
          this.errorMessage()
        })
    },
    approvedInflu () {
      this.$swal({
        title: this.$t('campaigns.approveInfluencers'),
        text: this.$t('campaigns.influencersSuccess'), 
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    rejectedInflu () {
      this.$swal({
        title: this.$t('campaigns.rejectInfluencers'),
        text: this.$t('campaigns.textReject'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    removedInflu () {
      this.$swal({
        title: this.$t('campaigns.deleteInfluencers'),
        text: this.$t('campaigns.textDelete'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    deletedCreator (uuid, name) {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: `${this.$t('campaigns.textConfirm')} ${name} ?`,
        text: this.$t('campaigns.textRemove'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirm'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service.influencerAction(this.$route.params.uuid, [uuid], 'removed')
            .then(() => {
              this.removedInflu()
              this.influencers = this.influencers.filter(i => i.uuid !== uuid);
              this.$emit('update_counter_header')
            })
            .catch(() => {
              this.errorMessage()
            })
        }
      })
    },
    errorMessage () {
      this.$swal({
        title: this.$t('campaigns.errormessage'),
        text: this.$t('campaigns.tryAgainLater'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-danger'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
    exportProposals() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.exportProposals(this.$route.params.uuid, this.checkedNames)
        .then(response => {
          const downloadLink = document.createElement("a");
          const blob = new Blob(["\ufeff", response]);
          const url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `Influencers ${this.campaign_name}.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
    getClasses(influencer) {
      if (influencer.engagement_rate > 0) return 'row mt-3 container-er-followers'
      return 'd-flex justify-content-center align-items-center mt-3'
    }
  },
  watch: {
    current_page() {
      if (this.change_view_card) {
        this.getInfluencers();
      }
    },
    change_view_card() {
      this.checkedNames = [];
      this.current_page = 1;
      this.getInfluencers();
    },
    "filters.type"(val) {
      const params = new URLSearchParams(this.filter_category);
      params.delete('category');
      this.filter_category = val.value === 'all' ? `&${params.toString()}` : `&category=${val.value}&${params.toString()}`;
      this.current_page = 1;
      this.getInfluencers();
    },
    "filters.status"(val) {
      this.status_influencers = val.value === 'production' ? 'producing' : val.value === 'proposals' ? 'all' : val.value;
      this.current_page = 1;
      this.getInfluencers();
    },
    "filters.networks"(val) {
      if (this.filter_category.includes('category')) {
        const params = new URLSearchParams(this.filter_category);  
        params.delete('networks');
        this.filter_category = `&${params.toString()}`
      } else this.filter_category = '';
      
      val.forEach(i => {
        this.filter_category += `&networks=${i.value}`
      })
      this.current_page = 1;
      this.getInfluencers();
    }
  }
}
</script>
<style scoped lang="scss">
.dropdown-actions-inner-influencers {
  width: auto;
  margin-right: 1em;
  z-index: 100;
  @media (max-width: 991.98px) {
    width: 100%;
    margin-right: 0;
  }
}
.icon-squad-network-avatar {
  width: 20px !important;
}
.dropdown-networks-filter-inner {
  z-index: 100;
  .ul-networks-select {
    li {
      list-style: none;
      padding: 0.7em 1.2em;

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
      &.active-li {
        background-color: #e9ecef;
      }
    }
  }
}
.container-loader-vue-good-table {
  background-color: rgba(255, 255, 255, 0.703);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 19;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-loader {
    width: 30px;
    height: 30px;
    color: #828282;
  }
}
.main-container-cell-id {
  width: 20px !important;
  height: 20.3px;
}
.cursor-pointer {
  cursor: pointer;
}
.h-min-empty {
  min-height: 400px;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.mr-05 {
  margin-right: 0.5em;
}
.card-skeleton {
  background-color: #fff;
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: .4rem;
  }
  .skeleton {
    &.profile-avatar {
      width: 200px;
      height: 200px;
      border-radius: 100%;
    }
  }
}
.mb-7 {
  margin-bottom: 7em;
}
.button-outline-header {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  padding: 10px;
  margin-left: 10px;

  &:active {
    background: rgb(243, 243, 243);
  }
}
.ml-05 {
  margin-left: 0.5em;
}
.body-button-center{
  position: relative;
  background-color: #e9ecef;
  padding: .25rem .25rem;
  border-radius: .375rem;

  @media (max-width: 991.98px) {
    width: 100%;
  }
}
.button-outline-header-grid-active {
  background: white;
  padding: 5px 10px;
  border-radius: 6px !important;
}

.button-outline-header-grid-no-active {
  background: transparent;
  padding: 5px 10px;
  border: none;
}
.gray-color-text {
  color: #8490a4;
  font-family: 'avenir-medium';
}
.avatar-absolute-net-influecer-list {
  position: absolute;
  left:4px 
}
</style>
<style lang="scss">

.dropdown-networks-filter-inner {
  width: auto;
  @media (max-width: 991.98px) {
    width: 100%;
  }
  height: 46px;
  .btn-link {
    color: #0f131a;
    font-family: 'avenir-heavy';
    padding: 11px 0px;
    width: 150px;
    text-align: left;
  }
  .dropdown-header, .dropdown-menu {
    padding: 0 !important;
    width: 100%;
    border-radius: 0.4em;
    overflow: hidden;
    font-family: 'avenir-medium';
  }
  .dropdown-menu {
    transform: translate3d(0px, 38px, 0px) !important;
  }
}

.vgt-table-influencers-inner-campaign {
  overflow: visible !important;
}
.vgt-table-influencers-inner-campaign tbody tr:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240) !important;
  transition: all 200ms;
}
.select-filters-type-inner-campaigns .vs__search::placeholder,
.select-filters-type-inner-campaigns .vs__dropdown-toggle
 {
  height: 46px ;
}
.select-filters-type-inner-campaigns .vs__clear {
  display: none;
}
.select-filters-type-inner-campaigns .vs__search {
  opacity: 0;
}
.vs__dropdown-menu {
  z-index: 100 !important;
}
.select-filters-type-inner-campaigns {
  width: auto;
  min-width: 250px;
  margin-right: 1em;

  @media (max-width: 991.98px) {
    width: 100%;
    margin-right: 0;
  }
}

.aditional-width {
  min-width: 320px;
}
#table-proposals-influencers {
  padding: 1em;
  width: 100%;
}
#table-proposals-influencers td {
  border-bottom: 1px solid #eff3f6;
}
#table-proposals-influencers tr {
  background-color: white;
}
#table-proposals-influencers tr:hover {
  background-color: #F5F8FA;
  cursor: pointer;
  transition: all 100ms;
}
.checkbox-table-searcher {
  display: none !important;
}
#table-proposals-influencers tr:hover .checkbox-table-searcher {
  display: block !important;
}
#table-proposals-influencers tr:hover .id-table-searcher {
  display: none;
}
  .without-border {
    border: none !important;
  }

  .socialnet-campaigns {
    font-size: 1.3em;
    position: absolute;
    left: 60%;
    bottom: 0;
    background: white;
    z-index: 9;
    padding: 2%;
    border-radius: 50%;
  }
  @media(min-width:2000px) {
    .socialnet-campaigns {
      left: 55%;
    }
  }
  @media(max-width: 992px) {
    .socialnet-campaigns {
      left: 54%;
    }
  }
  .card-all-users-profiles {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1) !important;

    height: 95%;
  }
  .img-200 {
    width: 200px
  }
  .h-450 {
    /* height: 1280px; */
    overflow: scroll;
    overflow-x: hidden
  }
  .delete-creator {
    right: 0;
    top: 0;
    z-index: 9
  }
  .square-20 {
    width: 20px;
    height: 20px;
  }
  .w-5rem {
    width: 5rem;
    height: 5rem;
  }
</style>
